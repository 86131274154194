import api from '@/app/core/services/api.service';
import ListUsersDto from '../dto/list-users.dto';
import EmailExistsDto from '../dto/email-exists.dto';
import RegisterUserDto from '../dto/register-user.dto';
import PaginatedUsersDto from '../dto/paginated-users.dto';

const emailExists = (email: string) => (
  api.get<EmailExistsDto>(`/users/email-exists/${email}`)
);

const registerUser = (dto: RegisterUserDto) => (
  api.post<PaginatedUsersDto>('/users', dto)
);

export const resendRegistrationEmail = (id: string) => (
  api.post<void>(`/users/resend-registration-email/${id}`)
);

export const deleteUser = (id: string) => (
  api.delete<void>(`/users/${id}`)
);

const listOwners = (params: ListUsersDto) => (
  api.get<PaginatedUsersDto>('/owners', { params })
);

const listAgents = (params: ListUsersDto) => (
  api.get<PaginatedUsersDto>('/agents', { params })
);

const listUsers = (params: ListUsersDto) => (
  api.get<PaginatedUsersDto>('/users', { params })
);

export default {
  listUsers,
  listOwners,
  listAgents,
  deleteUser,
  emailExists,
  registerUser,
  resendRegistrationEmail,
};
