import { Component } from 'vue-property-decorator';
import HandlesErrorMixin from '@/app/core/mixins/handles-error.mixin';
import { resendRegistrationEmail } from '../services/users.service';

@Component
export default class ResendsRegistrationEmailMixin extends HandlesErrorMixin {
  private resendingRegistrationEmail: string[] = [];

  async resendRegistrationEmail(id: string) {
    this.resendingRegistrationEmail.push(id);

    try {
      await resendRegistrationEmail(id);
      this.$notify.success(this.$t('success.registrationEmailResent').toString());
    } catch (error) {
      this.handleError(error);
    } finally {
      this.resendingRegistrationEmail = this.resendingRegistrationEmail.filter(_id => _id !== id);
    }
  }
}
