















































import { Getter } from 'vuex-class';
import { Component, Emit, Mixins, Prop } from 'vue-property-decorator';
import AuthModule from '@/app/auth/store';
import { Role } from '@/app/user/dto/user.dto';
import FormMixin from '@/app/core/mixins/form.mixin';
import { email, required } from '@/app/core/validation';
import HandlesErrorMixin from '@/app/core/mixins/handles-error.mixin';
import usersService from '../services/users.service';
import RegisterUserDto from '../dto/register-user.dto';

@Component
export default class RegisterUserForm extends Mixins(FormMixin, HandlesErrorMixin) {
  @Getter
  private isAdmin!: typeof AuthModule.prototype.isAdmin;

  @Prop({ type: Array })
  private allowedRoles!: Role[];

  protected data: RegisterUserDto = {
    email: '',
    role: Role.HostAgent,
  };

  protected rules = {
    email: [required, email],
    role: [required],
  };

  get roles() {
    const roles = this.allowedRoles || [Role.HostAdmin];
    return roles.map(role => ({ text: role, value: role }));
  }

  private emailExists = false;

  private checkingEmailExistence = false;

  private emailExistenceChecked = false;

  get emailExistsRule() {
    return !this.emailExists || this.$t('validation.emailExists');
  }

  get emailValid() {
    return this.rules.email.every((rule) => rule(this.data.email) === true);
  }

  @Emit('cancel')
  onCancel() {
    return false;
  }

  onEmailChanged() {
    this.emailExistenceChecked = false;
    this.emailExists = !this.emailValid;
  }

  async checkEmailExistence() {
    if (!this.emailValid) return;

    try {
      const response = await usersService.emailExists(this.data.email);
      this.emailExists = response.data.exists;
      this.emailExistenceChecked = true;
    } catch (error) {
      this.handleError(error);
    } finally {
      this.checkingEmailExistence = false;
    }
  }

  mounted() {
    if (this.isAdmin) {
      this.data.role = Role.HostAdmin;
    } else {
      this.data.role = this.allowedRoles.includes(Role.HostAgent)
        ? Role.HostAgent
        : Role.Owner;
    }
  }
}
