import { Component } from 'vue-property-decorator';
import UserDto from '@/app/user/dto/user.dto';
import HandlesErrorMixin from '@/app/core/mixins/handles-error.mixin';
import ConfirmationDialog from '@/app/core/components/confirmation-dialog.vue';
import { deleteUser } from '../services/users.service';

@Component
export default class DeletesUserMixin extends HandlesErrorMixin {
  private deletingUser: string[] = [];

  async confirm(user: UserDto) {
    const title = this.$t('title.deleteUser');
    const message = this.$t('message.confirmDeleteUser', { name: user.email });
    return this.$dialog.open(ConfirmationDialog, { title, message });
  }

  async deleteUser(user: UserDto) {
    if (!(await this.confirm(user))) return;

    this.deletingUser.push(user.id);
    try {
      await deleteUser(user.id);
      this.$emit('userDeleted');
      this.$notify.success(this.$t('success.userDeleted').toString());
    } catch (error) {
      this.handleError(error);
    } finally {
      this.deletingUser = this.deletingUser.filter(id => id !== user.id);
    }
  }
}
