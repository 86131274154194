





















import { Component, Emit, Mixins, Prop } from 'vue-property-decorator';
import { Role } from '@/app/user/dto/user.dto';
import HandlesErrorMixin from '@/app/core/mixins/handles-error.mixin';
import usersService from '../services/users.service';
import RegisterUserDto from '../dto/register-user.dto';
import RegisterUserForm from './register-user-form.vue';

@Component({
  components: {
    RegisterUserForm,
  },
})
export default class RegisterUserDialog extends Mixins(HandlesErrorMixin) {
  private loading = false;

  @Prop({ type: Array })
  private allowedRoles!: Role[];

  @Emit('close')
  close(dto: RegisterUserDto) {
    return dto || false;
  }

  async onSubmit(dto: RegisterUserDto) {
    this.loading = true;

    try {
      await usersService.registerUser(dto);

      this.$notify.success(this.$t('success.userRegistered').toString());
      this.close(dto);
    } catch (error) {
      this.handleError(error);
    } finally {
      this.loading = false;
    }
  }
}
